import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.text`
  font-size: 3em;
  margin: 0rem 0rem 2rem 0rem;
  color: ${(props) => (props.light ? "white" : props.theme.colors.dark)};
  text-align: center;
`;

export const Text = styled.text`
  font-size: 1.5em;
  color: ${(props) => (props.light ? "white" : props.theme.colors.dark)};
  text-align: center;
  margin-bottom: 0.5em;
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${(props) =>
    props.light ? "white" : props.theme.colors.primary};
  flex-direction: column;
  padding: 3rem 0rem 3rem 0rem;
  min-height: 30em;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.dark};
`;

export const SocialButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const SocialButton = styled.a`
  margin: 1em;
  border-radius: 2em;
  border-width: 3px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.dark};
  height: 4em;
  width: 4em;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.theme.colors.dark};
    * {
      color: white;
    }
  }
`;
