const GameData = [
  {
    imageName: "gunsandbounce.jpg",
    link: "https://apps.apple.com/app/id1660964163",
  },
  {
    imageName: "fashionevolution.jpg",
    link: "https://apps.apple.com/app/id1602114712",
  },
  {
    imageName: "trafficdriver.jpg",
    link: "https://apps.apple.com/app/id1634493780",
  },
  {
    imageName: "foodmayhem.jpg",
    link: "https://apps.apple.com/app/id1583546498",
  },
  {
    imageName: "strongrush.jpg",
    link: "https://apps.apple.com/app/id1564073544",
  },
  {
    imageName: "100.jpg",
    link: "https://apps.apple.com/app/id1231121830",
  },
  {
    imageName: "popular.jpg",
    link: "https://apps.apple.com/app/id1477763479",
  },
  {
    imageName: "dicecity.jpg",
    link: "https://apps.apple.com/app/id1571027746",
  },
  {
    imageName: "couple.jpg",
    link: "https://apps.apple.com/app/id1566735734",
  },
  {
    imageName: "strongcrowd.jpg",
    link: "https://apps.apple.com/app/id1573598877",
  },
  {
    imageName: "partygames.jpg",
    link: "https://apps.apple.com/app/id1577927697",
  },
  {
    imageName: "gearrush.jpg",
    link: "https://apps.apple.com/app/id1585173924",
  },
];

export default GameData;
