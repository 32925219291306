import React from "react";

import { Container, Title, Body } from "./legal.styles";
const DataDeletion = () => (
  <Container>
    <Title>Request for erasure of personal data</Title>
    <Body>
      We retain and process minimal personal data of our users, all of which is
      pseudonymous data, being data relating to a single unidentified person.
      For our purposes, the data we collect includes a unique pseudonymous
      identifier (your User ID); your user preferences; and milestones related
      to your gameplay. This is set out in our privacy policy.
    </Body>
    <Body>
      We are therefore unable to isolate your data from our other players’
      pseudonymous data profiles without a means of connecting you to your
      respective profile. This is part of our effort to create products and
      services which are private by design. Once the game is deleted by a
      player, the User ID is lost and so there are no means by which we can
      deduce which profile is yours.
    </Body>
    <Body>
      In accordance with our data retention policy, if the user profile is not
      accessed by a user for a period of time, it is moved to our inactive
      database. If after one year that data is still not used, it is permanently
      deleted.
    </Body>
    <Title>Erasing personal data</Title>
    <Body>
      Set out below are some measures you can take to ensure we do not further
      process your personal data, help you manage your settings and administer
      your consent withdrawal request for both iOS and Android.
    </Body>
    <Body>
      1. You can delete the application from your phone and not re-download it.
      If you do choose to download it again in the future, do not accept
      personalised ads when prompted.
    </Body>
    <Body>2. You can edit personalised ad settings on your device.</Body>

    <Title>For iOS:</Title>
    <Body>Limit Ad Tracking on your device by:</Body>
    <Body>1. Open the Settings App</Body>
    <Body>2. Tap Privacy</Body>
    <Body>3. Scroll down to the bottom of the page and tap Advertising</Body>
    <Body>4. Toggle the Limit Ad Tracking switch on</Body>
    <Body>
      After enabling Limit Ad Tracking, you will be able to clear the tracking
      data from your personal advertising identifier:
    </Body>
    <Body>1. Open the Settings App</Body>
    <Body>2. Tap Privacy</Body>
    <Body>3. Scroll down to the bottom of the page and tap Advertising</Body>
    <Body>4. Tap Reset Advertising Identifier</Body>
    <Body>5. When prompted, tap Reset Advertising Identifier</Body>

    <Title>For iOS 14 or later versions:</Title>
    <Body>1. Open the Settings App</Body>
    <Body>2. Tap Privacy</Body>
    <Body>
      3. Scroll down to the bottom of the page and tap Apple Advertising
    </Body>
    <Body>4. Toggle the Personalised Ad switch off</Body>

    <Body>
      You can turn off location-based iAds which will stop you from receiving
      advertisements based on your current location:
    </Body>
    <Body>1. Open the Settings App</Body>
    <Body>2. Tap Privacy</Body>
    <Body>3. Tap Location Services</Body>
    <Body>
      4. Scroll down to the bottom of the page and tap System Services
    </Body>
    <Body>5. Toggle the Location-Based iAds switch off</Body>

    <Title>For ANDROID:</Title>
    <Body>Limit Ad Tracking on your device by:</Body>
    <Body>1. Open the Settings App</Body>
    <Body>
      2. Search for Google services as depending on the device this may not
      appear on the main screen
    </Body>
    <Body>3. Tap Ads</Body>
    <Body>4. Turn on Opt out of Ads Personalization</Body>

    <Body>
      Under this menu you can also reset the advertising identifier that is
      currently assigned to them.
    </Body>
  </Container>
);

export default DataDeletion;
