import styled from "styled-components";

export const Container = styled.div`
  height: 5em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.darker};
`;

export const Text = styled.text`
  font-size: 0.75em;
  color: grey;
  font-family: "Montserrat-Regular";
`;
