import { createGlobalStyle } from "styled-components";
import MontserratMedium from "./fonts/Montserrat/Montserrat-Medium.ttf";
import MontserratBold from "./fonts/Montserrat/Montserrat-Bold.ttf";
import MontserratRegular from "./fonts/Montserrat/Montserrat-Regular.ttf";

export const GlobalStyle = createGlobalStyle`

	@font-face {
		font-family: 'Montserrat-Regular';
		src: local('Montserrat'), url(${MontserratRegular}) format('truetype');		
	}

	@font-face {
		font-family: 'Montserrat-Medium';
		src: local('Montserrat'), url(${MontserratMedium}) format('truetype');
	}

	@font-face {
		  font-family: 'Montserrat-Bold';
		  src: local('Montserrat'), url(${MontserratBold}) format('truetype');
	}

	body {				
		font-family: "Montserrat-Bold";
		
	}

	a {
		text-decoration: none;
		color: black;
	}

	* {
		box-sizing: border-box;
	}

	.content {
		width: 75%;
  		@media screen and (max-width: 800px) {
    		width: 90%;    
  		}
	}
`;

export const Theme = {
  colors: {
    dark: "#2b3f50",
    primaryLight: "#00ffaa",
    secondaryLight: "#76c6ff",
    primary: "#00a48b",
    secondary: "#0693ff",
    darker: "#1a2530",
  },
  spacing: {
    tiny: 4,
    small: 8,
    medium: 12,
    large: 16,
  },
};
