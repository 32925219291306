import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em 2em 2em 2em;

  * {
    font-family: "Montserrat";
  }
`;

export const Title = styled.text`
  font-size: 1.5em;
  margin-bottom: 1em;
`;

export const Body = styled.text`
  font-size: 1em;
  margin-bottom: 1em;
`;
