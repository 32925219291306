import React, { useRef } from "react";

import {
  Container,
  Title,
  InnerContainer,
  Text,
  SocialButton,
  Icon,
  SocialButtonsContainer,
} from "./home.styles";
import Games from "../../components/games/games.component";
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";

const Home = () => {
  const gamesRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  const onHeaderLinkClicked = (linkIndex) => {
    switch (linkIndex) {
      case 0:
        gamesRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case 1:
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case 2:
        contactRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <Header onLinkClick={onHeaderLinkClicked} />
      <InnerContainer light ref={gamesRef}>
        <Title>GAMES</Title>
        <Games />
      </InnerContainer>

      <InnerContainer ref={aboutRef}>
        <Title light>ABOUT US</Title>
        <Text className="content" light>
          We are a small but growing mobile game studio based in Turkey.
        </Text>
        <Text className="content" light>
          Building addictive games is our passion.
        </Text>
      </InnerContainer>

      <InnerContainer light ref={contactRef}>
        <Title>CONTACT</Title>
        <SocialButtonsContainer>
          <SocialButton
            href="https://www.linkedin.com/company/lagotgames"
            target="_blank"
          >
            <Icon icon={["fab", "linkedin-in"]} size="2x" />
          </SocialButton>
          <SocialButton href="mailto: contact@lagotgames.com" target="_blank">
            <Icon icon="envelope" size="2x" />
          </SocialButton>
        </SocialButtonsContainer>
      </InnerContainer>
      <Footer />
    </Container>
  );
};

export default Home;
