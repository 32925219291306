import React from "react";

import { Container, Text } from "./footer.styles";

const Footer = () => (
  <Container>
    <Text>Lagot Games © 2021</Text>
    <Text>Istanbul</Text>
  </Container>
);

export default Footer;
