import styled from "styled-components";

export const HeaderContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.dark};
`;

export const OptionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 5%;
`;

export const Logo = styled.img`
  height: 80px;
  padding-right: ${(props) => props.theme.spacing.large}px;
`;

export const Title = styled.div`
  color: white;
  font-size: 2em;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const RightContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: ${(props) => props.theme.spacing.large}px;
`;

export const OptionButton = styled.div`
  padding: 10px 15px;
  cursor: pointer;
`;

export const OptionLink = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  color: white;
  font-size: 1.2em;
  &:hover {
    color: ${(props) => props.theme.colors.primaryLight};
  }

  @media screen and (max-width: 800px) {
    font-size: 0.8em;
    padding: 5px 10px;
  }
`;

OptionLink.managerName = "OptionLink";
