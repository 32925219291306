import styled from "styled-components";

export const Container = styled.div`
  justify-content: center;
  display: flex;
`;

export const GamesContainer = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
