import { GlobalStyle, Theme } from "./global.styles";
import { Switch, Route, Redirect } from "react-router-dom";
import ErrorBoundary from "./components/error-boundary/error-boundary.component";
import Home from "./screens/home/home.component";
import PrivacyPolicy from "./screens/legal/privacy-policy.component";
import DataDeletion from "./screens/legal/data-deletion.component";
import { ThemeProvider } from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import TermsOfUse from "./screens/legal/terms-of-use";

library.add(faEnvelope, fab);

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={Theme}>
                <GlobalStyle />
                <ErrorBoundary>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route
                            path="*/privacypolicy"
                            component={PrivacyPolicy}
                        />
                        <Route path="*/termsofuse" component={TermsOfUse} />
                        <Route path="*/datadeletion" component={DataDeletion} />
                        <Redirect to="/" />
                    </Switch>
                </ErrorBoundary>
            </ThemeProvider>
        </div>
    );
}

export default App;
