import React from "react";

import {
  HeaderContainer,
  OptionsContainer,
  LeftContainer,
  RightContainer,
  OptionLink,
  Logo,
  Title,
} from "./header.styles";

const Header = ({ onLinkClick, ...otherProps }) => (
  <HeaderContainer {...otherProps}>
    <OptionsContainer>
      <LeftContainer>
        <Logo src="logo.png" />
        <Title>Lagot Games</Title>
      </LeftContainer>
      <RightContainer>
        <OptionLink onClick={() => onLinkClick(2)}>CONTACT</OptionLink>
        <OptionLink onClick={() => onLinkClick(1)}>ABOUT US</OptionLink>
        <OptionLink onClick={() => onLinkClick(0)}>GAMES</OptionLink>
      </RightContainer>
    </OptionsContainer>
  </HeaderContainer>
);

export default Header;
