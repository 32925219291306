import React from "react";

import { Container, Image, Link } from "./game.styles";

const Game = ({ data }) => (
  <Container>
    <Link href={data.link} target="_blank">
      <Image src={"portfolio/" + data.imageName} />
    </Link>
  </Container>
);

export default Game;
