import React from "react";

import { Container, Title, Body } from "./legal.styles";

const TermsOfUse = () => (
    <Container>
        <Title>Terms of Use</Title>
        <Body>
            <div>
                Welcome to our applications ("the Applications"). These Terms of
                Use ("Terms") govern your use of our Applications, so please
                read them carefully before accessing or using any of our
                Applications.
            </div>
        </Body>

        <Title>1. Acceptance of Terms</Title>
        <Body>
            <div>
                By accessing or using any of our Applications, you agree to be
                bound by these Terms and our Privacy Policy. If you do not agree
                to these Terms or our Privacy Policy, you may not access or use
                any of our Applications.
            </div>
        </Body>

        <Title>2. User Conduct</Title>
        <Body>
            <div>
                You agree to use our Applications only for lawful purposes and
                in accordance with these Terms and any applicable laws and
                regulations.
                <br />
                <br />
                You agree not to:
                <ul>
                    <li>
                        Use the Applications in any manner that could disable,
                        overburden, damage, or impair the Applications or
                        interfere with any other party's use of the
                        Applications.
                    </li>
                    <li>
                        Use any robot, spider, or other automatic device,
                        process, or means to access the Applications for any
                        purpose, including monitoring or copying any of the
                        material on the Applications.
                    </li>
                    <li>
                        Use any manual process to monitor or copy any of the
                        material on the Applications or for any other
                        unauthorized purpose without our prior written consent.
                    </li>
                    <li>
                        Use any device, software, or routine that interferes
                        with the proper working of the Applications.
                    </li>
                    <li>
                        Engage in any conduct that restricts or inhibits any
                        other user from using or enjoying the Applications.
                    </li>
                </ul>
            </div>
        </Body>

        <Title>3. Social Features and Chat (When Applicable)</Title>
        <Body>
            <div>
                Some of our Applications may include social features or chat
                functionalities that allow you to interact with other users. By
                using these features, you agree to:
                <ul>
                    <li>
                        Treat other users with respect and refrain from engaging
                        in any conduct that is offensive, discriminatory, or
                        otherwise inappropriate.
                    </li>
                    <li>
                        Not share personal information, such as your full name,
                        address, or contact information, with other users.
                    </li>
                    <li>
                        Not engage in any form of harassment, bullying, or
                        abusive behavior towards other users.
                    </li>
                    <li>
                        Not use the chat functionalities to distribute spam,
                        unsolicited advertising, or other unauthorized
                        promotional materials.
                    </li>
                </ul>
            </div>
        </Body>

        <Title>4. Intellectual Property</Title>
        <Body>
            <div>
                Our Applications and their entire contents, features, and
                functionality (including but not limited to all information,
                software, text, displays, images, video, and audio) are owned by
                us or our licensors and are protected by copyright, trademark,
                and other intellectual property laws.
                <br />
                You may not modify, copy, distribute, transmit, display,
                perform, reproduce, publish, license, create derivative works
                from, transfer, or sell any information, software, products, or
                services obtained from or through our Applications.
            </div>
        </Body>

        <Title>5. Termination</Title>
        <Body>
            <div>
                We reserve the right to terminate or suspend your access to any
                of our Applications at any time, without prior notice or
                liability, for any reason whatsoever, including without
                limitation if you breach these Terms.
            </div>
        </Body>

        <Title>6. Disclaimers</Title>
        <Body>
            <div>
                OUR APPLICATIONS ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
                BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                IMPLIED. WE MAKE NO WARRANTY THAT OUR APPLICATIONS WILL BE
                UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.
            </div>
        </Body>

        <Title>7. Limitation of Liability</Title>
        <Body>
            <div>
                IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                DAMAGES OF ANY KIND ARISING FROM OR RELATING TO THE USE OF OUR
                APPLICATIONS, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT,
                INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES.
            </div>
        </Body>

        <Title>8. Governing Law</Title>
        <Body>
            <div>
                These Terms and any dispute arising out of or related to these
                Terms or our Applications shall be governed by and construed in
                accordance with the laws, without regard to its conflict of law
                provisions.
            </div>
        </Body>

        <Title>9. Changes to These Terms</Title>
        <Body>
            <div>
                We reserve the right to update or modify these Terms at any time
                without prior notice. Any changes will be effective immediately
                upon posting the updated Terms on our Applications. Your
                continued use of our Applications after the posting of the
                updated Terms constitutes your acceptance of such changes.
            </div>
        </Body>

        <Title>10. Contact Us</Title>
        <Body>
            <div>
                If you have any questions or concerns about these Terms, please
                contact us at contact@lagotgames.com.
            </div>
        </Body>
    </Container>
);

export default TermsOfUse;
