import styled from "styled-components";

export const Container = styled.div`
  aspect-ratio: 1;
  border-radius: ${(props) => props.theme.spacing.medium}px;
  overflow: hidden;
`;

export const Link = styled.a``;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;
