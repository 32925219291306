import React from "react";
import Game from "../game/game.component";
import GameData from "../../assets/gamedata";
import { Container, GamesContainer } from "./games.styles";

const Games = () => (
  <Container>
    <GamesContainer className="content">
      {GameData.map((gd) => {
        return <Game key={gd.link} data={gd} />;
      })}
    </GamesContainer>
  </Container>
);

export default Games;
